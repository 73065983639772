
function navigateToSection(path) {
    history.pushState({}, '', path);
    const sectionId = path.substring(1);
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
}

const enlaces = document.querySelectorAll('.nav-link-page');

enlaces.forEach(enlace => {
    enlace.addEventListener('click', (event) => {
        event.preventDefault();
        const urlObject = new URL(enlace.href);
        navigateToSection(urlObject.pathname);
    });
});

window.addEventListener('popstate', function (event) {
    const sectionId = window.location.pathname.substring(1);
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
});

function countTo(target, element) {
    const start = 0;
    const duration = 2000;
    const step = Math.abs(target - start) / duration * 10;

    let current = start;
    const timer = setInterval(() => {
        if (current >= target) {
            clearInterval(timer);
        }
        element.textContent = Math.ceil(current);
        current += step;
    }, 10);
}

function startCounting() {
    const numberElements = document.querySelectorAll('.counter');
    numberElements.forEach((element) => {
        const target = parseInt(element.dataset.target, 10);
        countTo(target, element);
    });
}


var countActivo = false;

function getScrollPosition() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > 300 && countActivo == false) {
        countActivo = true;
        startCounting();
    }
}

window.addEventListener('scroll', getScrollPosition);



// Infinitum({});